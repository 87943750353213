import { useContext, useEffect, useState } from "react";
import empty from "../../assets/loupe.png";
import { useAccount } from "wagmi";
import axios from "axios";
import LoadingHist from "./LoadingHistory";
import unPlugged from "../../assets/connection.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const History = ({ setter }) => {
  const [loading, setLoading] = useState(true);
  const [claimedtxEth, setclaimedtxEth] = useState([]);
  const [claimedtxShib, setclaimedtxShib] = useState([]);

  const [fromEth, setFromEth] = useState(true);
  const { address, isConnected } = useAccount();

  const fetchUsedTx = async () => {
    const claimedTxs = await axios.get(
      `https://paradoxserver.onrender.com/api/v1/users/usedtransactions?address=${address}`
    );

    if (claimedTxs.data.allUsedTx === undefined) {
      setLoading(false);
      return;
    }

    setclaimedtxEth(claimedTxs.data.allUsedTx.eth);
    setclaimedtxShib(claimedTxs.data.allUsedTx.bsc);

    setLoading(false);
    console.log("f");
  };

  useEffect(() => {
    if (isConnected) {
      fetchUsedTx();
    } else {
      notify();
    }
  }, []);

  const txList = fromEth ? claimedtxEth : claimedtxShib;

  const notify = () =>
    toast.error("Not Connected!", {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  return (
    <>
      <section
        onClick={() => setter(false)}
        className="bg-[#0000004d] fixed w-full bottom-0 right-0 left-0 top-0 z-50 flex items-center justify-center"
      ></section>
      <div className="bg-[#0c1620] h-[400px] overflow-y-auto w-[350px] px-3 fixed -translate-y-[50%] z-[51] -translate-x-[50%] left-[50%] top-[50%] py-10 flex flex-col items-center rounded-lg">
        <h2 className="text-xl text-white font-bold">Transaction History:</h2>
        <div className="text-white mt-4 flex w-full p-1 max-w-[300px] rounded-md border-2 border-[#233242]">
          <button
            onClick={() => setFromEth(true)}
            className={
              fromEth
                ? "text-white w-full p-1 bg-[#1E2F40]"
                : "text-white w-full p-1"
            }
          >
            <span>From ETH</span>
          </button>
          <button
            onClick={() => setFromEth(false)}
            className={
              !fromEth
                ? "text-white w-full p-1 bg-[#1E2F40]"
                : "text-white w-full p-1"
            }
          >
            <span>From BSC</span>
          </button>
        </div>

        {isConnected ? (
          <ul className="mt-5 max-w-[300px] w-full flex flex-col gap-2">
            {loading ? (
              <div className="w-full mt-20 flex justify-center items-center">
                <LoadingHist />
              </div>
            ) : (
              <>
                {txList.length === 0 ? (
                  <div className="text-white border-2 border-[#233242] p-2 text-center mt-10 rounded-md">
                    No trasactions recorded
                  </div>
                ) : (
                  <>
                    {txList.map((item) => (
                      <li className="text-white p-2 flex flex-col rounded-xl border-2 border-[#233242]">
                        <div className="flex justify-between">
                          <span className="text-xs italic">
                            {fromEth ? "Eth to BSC" : "BSC to Eth"}
                          </span>
                          <span className="text-xs bold">
                            Trans. Num {item.nonce}
                          </span>
                        </div>

                        <span className="p-2 text-center w-full bg-[#1e2f40] rounded-md border-2 border-[#233242] mt-5">
                          Amount: {item.amount} $PRDX
                        </span>
                      </li>
                    ))}
                  </>
                )}
              </>
            )}
          </ul>
        ) : (
          <h2 className="mt-10 flex flex-col gap-5 items-center">
            <img
              src={unPlugged}
              alt=""
              className="w-28"
            />
            <span className="text-2xl text-white">Not Connected</span>
          </h2>
        )}
      </div>
    </>
  );
};

export default History;
