import { ethers } from "ethers";
import { createContext } from "react";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const ethBridgeAddr = "0x7DfB4C9d74c1F95D6270aEcB3f8E114c75E4a667";
  const bscBridgeAddr = "0xf7839af59F2908d15F618F9c315B48155e048AE1";

  const paraEthAddr = "0xA4bF9CeE4Ca4A870b922dA7c76848c3Eb222d942";
  const paraBSCAddr = "0x0213e7cb4BAD2F739AF4723B28373831cB5c46d1";

  const ethStProv = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/eth"
  );

  const bscStProv = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/bsc"
  );

  return (
    <AppContext.Provider
      value={{
        ethStProv,
        bscStProv,
        ethBridgeAddr,
        bscBridgeAddr,
        paraEthAddr,
        paraBSCAddr,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
