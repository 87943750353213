const Footer = () => {
  return (
    <footer className="w-full shadow-2xl border-b border-[#3e4e60] flex justify-center">
      <div className="max-w-screen-2xl text-center w-full flex items-center justify-center text-gray-200 italic mt-10 p-5">
        Copyright © 2023 All rights reserved. Paradox.
      </div>
    </footer>
  );
};

export default Footer;
