import logo from "../../assets/logo.svg";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { shortAdd } from "../../utils";

const Header = () => {
  const { open } = useWeb3Modal();

  const { address, isConnected } = useAccount();

  const connectWallet = () => {
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header className="w-full shadow-2xl border-b border-[#3e4e60] flex justify-center">
      <div className="max-w-screen-2xl w-full flex items-center justify-between p-5">
        <h1 className="flex items-center gap-4 text-[30px]  text-white">
          <img
            src={logo}
            alt=""
            className="w-7 lg:w-12"
          />
          <span className="hidden lg:flex">Paradox</span>
        </h1>

        <button
          onClick={connectWallet}
          className="border-2 font-bold border-[#fff] text-[#e4e4e4] px-4 lg:px-7 py-2 lg:py-3 rounded-[25px] flex gap-3"
        >
          {isConnected ? shortAdd(address) : "Connect Wallet"}
        </button>
      </div>
    </header>
  );
};

export default Header;
