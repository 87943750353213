import { ethers } from "ethers";

/**  Displays the first and last 4 characters of a given address */
export const shortAdd = (address) => {
  return address.slice(0, 4) + "..." + address.slice(-4);
};

/**  Displays the first and last 10 characters of a given address */
export const longAdd = (address) => {
  return address.slice(0, 10) + "..." + address.slice(-10);
};

export const fromBn = (num) => {
  return Number(ethers.utils.formatUnits(num, 18)).toFixed(0);
};

export const fromBn18 = (num) => {
  return Number(ethers.utils.formatUnits(num, 0)).toFixed(0);
};

export const toBn = (num) => {
  return ethers.utils.parseEther(num.toString());
};
