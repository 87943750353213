import bridge from "./assets/bridge.png";
import shibarium from "./assets/binance.svg";
import ethereum from "./assets/ethereum.png";
import arrow from "./assets/arrow.png";
import bone from "./assets/logo.svg";
import clock from "./assets/history.png";
import to from "./assets/to.png";
import ok from "./assets/confirm.png";

import tgIcon from "./assets/telegram.png";
import twtIcon from "./assets/twitter.png";
import medIcon from "./assets/medium.png";

import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { fromBn, toBn } from "./utils";
import History from "./components/UI/History";

import paraEthABI from "./contracts/ebone.json";
import paraBscABI from "./contracts/sbone.json";
import ethBrABI from "./contracts/ethBridge.json";
import shibBrABI from "./contracts/shibBridge.json";
import axios from "axios";
import { AppContext } from "./context/appContext";
import LoadingHash from "./components/UI/LoadingHash";
import { toast } from "react-toastify";

const Home = () => {
  const [history, setHistory] = useState(false);
  const [bridgeInputAmount, setbridgeInputAmount] = useState(0);

  // User Related Data
  const [eboneBal, setEboneBal] = useState(-1);
  const [sboneBal, setSboneBal] = useState(-1);
  const [ethTransactions, setEthTransactions] = useState([]);
  const [shibTransactions, setShibTransactions] = useState([]);

  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [invert, setInvert] = useState(false);
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  const {
    ethStProv,
    bscStProv,
    ethBridgeAddr,
    bscBridgeAddr,
    paraEthAddr,
    paraBSCAddr,
  } = useContext(AppContext);

  const ethParaReadContract = new ethers.Contract(
    paraEthAddr,
    paraEthABI,
    ethStProv
  );

  const bscParaReadContract = new ethers.Contract(
    paraBSCAddr,
    paraBscABI,
    bscStProv
  );

  const fetchPendingTx = async () => {
    try {
      const response = await axios.get(
        `https://paradoxserver.onrender.com/api/v1/users/getuser?address=${address}`
      );

      const claimedTxs = await axios.get(
        `https://paradoxserver.onrender.com/api/v1/users/usedtransactions?address=${address}`
      );

      const ethClaimedNonces = claimedTxs?.data?.allUsedTx?.eth.map(
        (item) => item.nonce
      );

      const shibClaimedNonces = claimedTxs?.data?.allUsedTx?.bsc.map(
        (item) => item.nonce
      );

      const filteredEth = response.data?.eth?.filter(
        (item) => ethClaimedNonces?.indexOf(item.nonce) === -1
      );

      const filteredShib = response?.data?.bsc?.filter(
        (item) => shibClaimedNonces?.indexOf(item.nonce) === -1
      );

      setEthTransactions(filteredEth);
      setShibTransactions(filteredShib);

      console.log(filteredEth);
      console.log("middle");
      console.log(filteredShib);
    } catch (error) {
      console.log("error", error);
    }
  };

  const connectWallet = () => {
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const getUserBal = async () => {
    const ebal = await ethParaReadContract.balanceOf(address);
    const sbal = await bscParaReadContract.balanceOf(address);

    setEboneBal(fromBn(ebal));
    setSboneBal(fromBn(sbal));
  };

  useEffect(() => {
    if (isConnected) {
      getUserBal();
      fetchPendingTx();
      setInterval(fetchPendingTx, 10000);
    }
  }, [isConnected, address]);

  const changeInputAmount = (e) => {
    setbridgeInputAmount(e.target.value);
  };

  const bridgeFromEth = async () => {
    if (!isConnected) return notConnected();

    const eboneContr = new ethers.Contract(paraEthAddr, paraEthABI, signer);
    const ethBridgeContr = new ethers.Contract(ethBridgeAddr, ethBrABI, signer);

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      const checkApproved = await eboneContr.allowance(address, ethBridgeAddr);

      if (fromBn(checkApproved) < bridgeInputAmount) {
        const appr = await eboneContr.approve(
          ethBridgeAddr,
          toBn(bridgeInputAmount)
        );

        await appr.wait();

        toast.success("Tokens Approved!", {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      const deposit = await ethBridgeContr.deposit(toBn(bridgeInputAmount), 56);
      await deposit.wait();

      toast.success("Tokens Deposited!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getUserBal();
      fetchPendingTx();
    } catch (error) {
      console.log(error);
    }
  };

  const bridgeFromShib = async () => {
    if (!isConnected) return notConnected();

    if (chain?.id !== 56) {
      switchNetwork?.(56);
    }

    const sboneContr = new ethers.Contract(paraBSCAddr, paraBscABI, signer);
    const shibBridgeContr = new ethers.Contract(
      bscBridgeAddr,
      shibBrABI,
      signer
    );

    try {
      const checkApproved = await sboneContr.allowance(address, bscBridgeAddr);

      if (fromBn(checkApproved) < bridgeInputAmount) {
        const appr = await sboneContr.approve(
          bscBridgeAddr,
          toBn(bridgeInputAmount)
        );

        await appr.wait();

        toast.success("Tokens Approved!", {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      const deposit = await shibBridgeContr.deposit(toBn(bridgeInputAmount), 1);
      await deposit.wait();

      toast.success("Tokens Deposited!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getUserBal();
      fetchPendingTx();
    } catch (error) {
      console.log(error);
    }

    getUserBal();
  };

  const claimOnShib = async (user, amount, nonce, network, signature) => {
    if (chain?.id !== 56) {
      switchNetwork?.(56);
    }

    const shibBridgeContr = new ethers.Contract(
      bscBridgeAddr,
      shibBrABI,
      signer
    );

    try {
      const claim = await shibBridgeContr.claim(
        user,
        1,
        56,
        toBn(amount).toString(),
        nonce,
        signature
      );

      await claim.wait();

      toast.success("Tokens Bridged!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      fetchPendingTx();
      getUserBal();
    } catch (error) {
      console.log(error);
    }

    console.log(user, amount, nonce, network, signature);
  };

  const claimOnEth = async (user, amount, nonce, signature) => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    const ethBridgeContr = new ethers.Contract(ethBridgeAddr, ethBrABI, signer);

    try {
      const claim = await ethBridgeContr.claim(
        user,
        56,
        1,
        toBn(amount).toString(),
        nonce,
        signature
      );

      await claim.wait();

      toast.success("Tokens Bridged!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      fetchPendingTx();
      getUserBal();
    } catch (error) {
      console.log(error, "wasd");
    }
  };

  const bridgeFunds = async () => {
    if (!isConnected) {
      connectWallet();
    }

    if (!invert) {
      bridgeFromEth();
    } else {
      bridgeFromShib();
    }
  };

  const maxInput = () => {
    if (!isConnected) return;
    // Change according to token
    if (!invert) {
      setbridgeInputAmount(eboneBal);
    } else {
      setbridgeInputAmount(sboneBal);
    }
  };

  const notConnected = () =>
    toast.error("Not Connected!", {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  return (
    <section className="w-full flex justify-center">
      {history && <History setter={setHistory} />}
      <div className="max-w-screen-2xl px-1 w-full flex flex-col items-center justify-center">
        <img
          alt=""
          src={bridge}
          className="w-60 lg:w-auto mt-10 lg:mt-32 mb-4 lg:mb-20"
        />
        {/* Main */}
        <div className="flex mb-2 shadow-lg px-2 bg-[#000000] flex-col max-w-[700px] items-center py-5 rounded-2xl w-full border-2 border-[#233242]">
          <div className="text-white font-normal text-center text-2xl md:text-3xl mt-10 w-full">
            <h2 className="ml-10">Choose Blockchain</h2>
          </div>

          <div className="flex justify-center gap-2 text-white max-w-[550px] w-full items-center py-5 mt-5">
            <div className=" relative flex flex-col max-w-[250px] w-full justify-center items-center">
              {!invert ? (
                <div className="h-16 md:h-20 px-5 md:px-8 border-2 border-[#233242] rounded-[15px] w-full flex justify-center items-center text-sm md:text-xl gap-2 md:gap-5">
                  <img
                    src={ethereum}
                    alt=""
                    className="w-5 md:w-7"
                  />
                  Ethereum
                </div>
              ) : (
                <div className="h-16 md:h-20 px-5 md:px-8 border-2 border-[#233242] rounded-[15px] w-full flex justify-center items-center text-sm md:text-xl gap-2 md:gap-5">
                  <img
                    src={shibarium}
                    alt=""
                    className="w-7 md:w-10"
                  />
                  Binance Chain
                </div>
              )}
            </div>

            <div className="">
              <button
                onClick={() => {
                  setInvert((prevState) => !prevState);
                }}
                className="border-2 border-[#0CC762] w-5 h-5 rounded-full flex justify-center items-center p-1"
              >
                <img
                  src={arrow}
                  alt=""
                  className="w-2 rotate-90"
                />
              </button>
            </div>

            <div className="relative flex flex-col max-w-[250px] w-full justify-center items-center">
              {invert ? (
                <div className="h-16 md:h-20 px-5 md:px-8 border-2 border-[#233242] rounded-[15px] w-full flex justify-center items-center text-sm md:text-xl gap-2 md:gap-5">
                  <img
                    src={ethereum}
                    alt=""
                    className="w-5 md:w-7"
                  />
                  Ethereum
                </div>
              ) : (
                <div className="h-16 md:h-20 px-4 md:px-8 border-2 border-[#233242] rounded-[15px] w-full flex justify-center items-center text-sm md:text-lg gap-2 md:gap-5">
                  <img
                    src={shibarium}
                    alt=""
                    className="w-7 md:w-10"
                  />
                  Binance Chain
                </div>
              )}
            </div>
          </div>

          <div className="flex border-2 border-[#233242] rounded-[15px] max-w-[550px] justify-center text-white w-full items-center">
            <div className="flex w-full justify-center items-center">
              {!invert ? (
                <button className="h-20 justify-center px-8 p-3 max-w-[300px] w-full flex items-center text-sm md:text-xl gap-2">
                  <img
                    src={bone}
                    alt=""
                    className="h-10 w-10 p-2 bg-black rounded-full"
                  />
                  Paradox (ETH)
                </button>
              ) : (
                <button className="h-20 justify-center px-8 p-3 max-w-[300px] w-full flex items-center text-sm md:text-xl gap-2">
                  <img
                    src={bone}
                    alt=""
                    className="h-10 w-10 p-2 bg-black rounded-full"
                  />
                  Paradox (BSC)
                </button>
              )}
            </div>

            <img
              src={to}
              alt="arrow"
              className="w-7"
            />

            <div className="flex w-full justify-center items-center">
              {invert ? (
                <div className="h-20 justify-center px-8 p-3 max-w-[300px] w-full flex items-center text-sm md:text-xl gap-2">
                  <img
                    src={bone}
                    alt=""
                    className="h-10 w-10 p-2 bg-black rounded-full"
                  />
                  Paradox (ETH)
                </div>
              ) : (
                <div className="h-20 justify-center px-8 p-3 w-full flex items-center text-sm md:text-xl gap-2">
                  <img
                    src={bone}
                    alt=""
                    className="h-10 w-10 p-2 bg-black rounded-full"
                  />
                  Paradox (BSC)
                </div>
              )}
            </div>
          </div>

          <div className="py-5 flex items-center justify-center gap-4 max-w-[600px] w-full">
            <div className="relative">
              <input
                onChange={changeInputAmount}
                value={bridgeInputAmount}
                type="number"
                min={0}
                className="px-3 w-full max-w-[300px] outline-none flex items-center text-xl justify-center gap-2 bg-[#192530] text-gray-200 h-[60px] rounded-lg"
              />
              <button
                onClick={maxInput}
                className="absolute text-sm right-2 top-2 text-[#192530] px-2 rounded-md bg-[#0CC762]"
              >
                Max.
              </button>
              <span className="text-gray-300 absolute text-xs right-2 bottom-1">
                Balance:{" "}
                {!invert
                  ? eboneBal === -1
                    ? "Not Connected"
                    : eboneBal
                  : sboneBal === -1
                  ? "Not Connected"
                  : sboneBal}
              </span>
            </div>

            <button
              onClick={bridgeFunds}
              className="flex items-center h-[60px] text-md md:text-xl justify-center gap-2 bg-[#1e2c39] text-gray-200 w-[250px] rounded-lg"
            >
              <img
                src={ok}
                alt=""
                className="w-3 md:w-5"
              />
              Bridge Funds
            </button>
          </div>
        </div>

        {/* Claim */}
        <div className="w-full flex flex-col items-center justify-center">
          <div className="mt-2 flex flex-col shadow-lg px-2 bg-[#000000] justify-center gap-2 max-w-[700px] items-center py-5 rounded-2xl w-full border-2 border-[#233242]">
            <div className="text-white flex gap-1 mb-5">
              <button
                onClick={() => setInvert(false)}
                className={
                  invert
                    ? "rounded-md border-[#3b536e] rounded-r-none border p-2 w-[160px]"
                    : "bg-[#1E2F40] border-[#3b536e] rounded-md rounded-r-none border p-2 w-[160px]"
                }
              >
                Eth To BSC
              </button>
              <button
                onClick={() => setInvert(true)}
                className={
                  !invert
                    ? "rounded-md border-[#3b536e] rounded-l-none border p-2 w-[160px]"
                    : "bg-[#1E2F40] border-[#3b536e] rounded-md rounded-l-none border p-2 w-[160px]"
                }
              >
                BSC To Eth
              </button>
            </div>
            {console.log(shibTransactions)}
            {console.log(ethTransactions)}

            {isConnected ? (
              <div className="flex flex-wrap justify-center gap-4">
                {invert ? (
                  <>
                    {shibTransactions?.map((item) => (
                      <div
                        key={Number(item.nonce) * 1000}
                        className="flex flex-col items-center text-md justify-center gap-2 bg-[#243545] text-gray-200 w-[280px] h-[80px] rounded-lg"
                      >
                        <span className="flex items-center text-sm justify-center gap-2 bg-[#243545] text-gray-200 w-[280px] rounded-lg">
                          Transaction Number {item.nonce}
                        </span>

                        {item.signedMessageHash === "" ? (
                          <div className="flex items-center gap-2">
                            Procesing <LoadingHash />
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              claimOnEth(
                                item.sender,
                                item.amount,
                                item.nonce,
                                item.signedMessageHash
                              )
                            }
                            className="text-[14px] bg-[#1E2F40] hover:bg-[#0e1a25] border border-[#33516e] w-[150px] rounded-md p-1"
                          >
                            Claim {item.amount} $WPDX
                          </button>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {ethTransactions?.map((item) => (
                      <div
                        key={item.nonce}
                        className="flex flex-col items-center text-md justify-center gap-2 bg-[#243545] text-gray-200 w-[280px] h-[80px] rounded-lg"
                      >
                        <span className="flex items-center text-sm justify-center gap-2 bg-[#243545] text-gray-200 w-[280px] rounded-lg">
                          Transaction Number {item.nonce}
                        </span>
                        {console.log(item.signedMessageHash === "")}
                        {item.signedMessageHash === "" ? (
                          <div className="flex items-center gap-2">
                            Processing <LoadingHash />
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              claimOnShib(
                                item.sender,
                                item.amount,
                                item.nonce,
                                "1",
                                item.signedMessageHash
                              )
                            }
                            className="text-[14px] bg-[#1E2F40] hover:bg-[#0e1a25] border border-[#33516e] w-[150px] rounded-md p-1"
                          >
                            Claim {item.amount} $PRDX
                          </button>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <h2 className="text-gray-100">
                Please, Connect to display transactions.
              </h2>
            )}
          </div>
        </div>

        {/* Social Media */}
        <div className="flex flex-wrap px-3 gap-2 justify-between max-w-[700px] items-center py-5 rounded-2xl w-full">
          <ul className="flex gap-2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/Eigen_Labs"
            >
              <li>
                <img
                  src={tgIcon}
                  alt="telegram"
                  className="w-10"
                />
              </li>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/paradoxexchange"
            >
              <li>
                <img
                  src={twtIcon}
                  alt="twitter"
                  className="w-10"
                />
              </li>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/eigen-labs/"
            >
              <li>
                <img
                  src={medIcon}
                  alt="medium"
                  className="w-10"
                />
              </li>
            </a>
          </ul>

          <div className="flex gap-2">
            <button
              onClick={() => setHistory(true)}
              className="flex items-center text-sm justify-center gap-2 bg-[#243545] text-gray-200 w-[150px] h-[50px] rounded-lg"
            >
              <img
                src={clock}
                alt=""
                className="w-5"
              />{" "}
              History
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
